import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import themeKym from "../../utils/themes/ThemeKym";

const SelectMaterial = (data) => {
  const [actualValue, setActualValue] = useState(data.props.value);
  const [listItems, setListItems] = useState([]);
  const classesKym = themeKym();

  const handleFunction = (e: SelectChangeEvent) => {
    setActualValue(e.target.value);
    const { value } = e.target;
    data.setFieldValue(data.props.name, value);
    data.onchange(data, value);
  };

  const buildListItems = () => {
    const listType = data.selectItems[`${data.props.listType}`];
    setListItems(listType.concat(data.data));
  };

  useEffect(async () => {
    buildListItems();
  }, [data.selectItems]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel className={classesKym[`${data.props.classes.label}`]}>{data.props.label}</InputLabel>
        <Select
          id={data.props.id}
          value={actualValue}
          label={data.props.label}
          placeholder={data.props.placeholder}
          className={classesKym[`${data.props.classes.select}`]}
          onChange={handleFunction}
        >
          {listItems.map((op) => (
            <MenuItem key={op.id} className={classesKym[`${data.props.classes.menuItem}`]} value={op.defaulValue}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default SelectMaterial;
