import { React, useEffect, useState } from "react";

import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { Formik, Form as Formx, useFormikContext } from "formik";
import PropTypes from "prop-types";

import FooterContainer from "./footerContainer";
import GenericButton from "./genericButton";
import GenericCellElement from "./genericCellElements";
import GenericInput from "./genericInput";
import ErrorMessage from "./inputs/errorMessage";

import { Icons } from "../utils/icons-material";
import { GetWCProperties } from "../utils/segment";
import themeKym from "../utils/themes/ThemeKym";
import { getYup, schema } from "../utils/yup";

const Form = (props_) => {
  let {
    countryCode,
    telephoneOTP,
    formDataStruct,
    form,
    btnNext,
    btnBefore,
    sectionType = false,
    idpdv,
    temporalData,
    fileLoaded = false,
    selectItems,
    userData,
    setOTP,
    btnResendOTP,
    messageOTP,
    setMessageOTP,
    setRestartTimer,
    restartTimer,
    pinComplete,
    setPinComplete,
    timerProps,
    isLegacy = false,
    btnNextLegacy,
    btnLegacyValidateOTP,
    userDataLegacy,
    isWorkingCapital,
    screen,
    setScreen,
    prefilledData,
    phone,
    errorMSISDN,
    setErrorMSISDN,
  } = props_;
  let validates = {};
  let initialValue = {};
  let auxSection = [];
  const [props, setProps] = useState({ width: window.innerWidth });
  const classesKym = themeKym(props);
  let section = sectionType
    ? form.section.filter((x) => x.sectionType === undefined || x.sectionType === sectionType)
    : form.section;
  if (isLegacy) {
    section.forEach((data) => {
      if (data.sectionType === userDataLegacy?.Negocio.business_type.type_of_company) {
        auxSection.push(data);
      }
    });
    section = auxSection;
  }
  const footer = form.footer ? form.footer.fields : [];
  const btns = form.actionsButtons;
  const specialbuttons = form.specialbuttons;
  const classesForm = form.classes ? form.classes : {};
  const formClass = classesForm.form ? classesForm.form : "formulario";
  let prefilledData_ = prefilledData;

  const prefilled = (props) => {
    let params = props;
    switch (params.prefilled) {
      case "rucWC":
        params["original_value"] = prefilledData_?.ruc;
        params["prefilled"] = true;
        params.value = prefilledData_?.ruc;
        initialValue[params.name] = params.value ?? prefilledData_?.ruc;
        break;
      case "nameWC":
        params["original_value"] = prefilledData_?.name;
        params["prefilled"] = true;
        params.value = prefilledData_?.name;
        initialValue[params.name] = params.value ?? prefilledData_?.last_name;
        break;
      case "lastnameWC":
        params["original_value"] = prefilledData_?.last_name;
        params["prefilled"] = true;
        initialValue[params.name] = params.value ?? prefilledData_?.last_name;
        params.value = prefilledData_?.last_name;
        break;
      default:
        initialValue[params.name] = params.value ?? "";
    }
  };

  const findFieldsValidations = (fields) => {
    fields.forEach(({ props, validations, data }) => {
      if (props.type === "column") {
        findFieldsValidations(props.fields);
      } else {
        prefilled(props);
        validates[props.name] = schema(
          validations,
          props.type,
          data ?? [].length > 0 ? data.slice(1).map((x) => x.label) : []
        );
      }
    });
  };

  const validaciones = (fields) => {
    fields.forEach(({ props, validations, data }) => {
      if (props.type === "column") {
        validaciones(props.fields);
      } else {
        validates[props.name] = schema(
          validations,
          props.type,
          data ?? [].length > 0 ? data.slice(1).map((x) => x.label) : []
        );
      }
    });
  };

  const valoresIniciales = (fields) => {
    fields.forEach(({ props }) => {
      if (props.type === "column") {
        valoresIniciales(props.fields);
      } else {
        initialValue[props.name] = props.value ?? "";
      }
    });
  };

  const addErr = (field, err) => {
    initialValue = {};
    section.forEach((sec) => {
      validaciones(sec.fields);
      findFieldsValidationsWithRadio(sec);
    });

    if (footer.length > 0) {
      footer.forEach(({ props, validations }) => {
        initialValue[props.name] = "";
        validates[props.name] = schema(validations, props.type, []);
      });
    }

    for (let i in err) {
      if (!initialValue.hasOwnProperty(i)) {
        delete err[i];
      }
    }
    field.errors = err;
    return field;
  };

  const findFieldsValidationsWithRadio = (sec) => {
    if (sec.hasOwnProperty("radio")) {
      if (initialValue[sec.radio.name] === sec.radio.value) {
        valoresIniciales(sec.fields);
      }
    } else {
      valoresIniciales(sec.fields);
    }
  };

  section.forEach((sec) => {
    findFieldsValidations(sec.fields);
  });

  if (footer.length > 0) {
    footer.forEach(({ props, validations }) => {
      initialValue[props.name] = "";
      validates[props.name] = schema(validations, props.type, []);
    });
  }

  const sectionBuild = (sec, j, values) => {
    return (
      <Grid
        className={`${classesKym.sectionContainer} ${sec.sectionName}`}
        id={"sec-" + sec.id}
        key={j}
        item
        xs={11}
        md={12 / (section.length >= 3 ? 3 : section.length)}
      >
        <Stack direction="row" spacing={2} mb={3}>
          {sec.sectionName !== "ghost" && sec.icon ? (
            <Avatar className={classesKym.AvatarForm}>{Icons(sec.icon)}</Avatar>
          ) : (
            ""
          )}
          <Typography align="left" className={classesKym.TitleSection} gutterBottom component="div">
            {sec.title}
          </Typography>
        </Stack>
        {sec.fields.map((field) => (
          <div key={field.props.id} style={{ marginTop: "20px" }}>
            <GenericInput
              {...addErr(field, values.errors)}
              {...values}
              countryCode={countryCode}
              eventRadioBtn={eventRadioButton}
              onchange={OnChanceDepence}
              setOTP={setOTP}
              messageOTP={messageOTP}
              setMessageOTP={setMessageOTP}
              telephoneOTP={telephoneOTP}
              phone={phone}
              formDataStruct={formDataStruct}
              setRestartTimer={setRestartTimer}
              restartTimer={restartTimer}
              setPinComplete={setPinComplete}
              timerProps={timerProps}
              errorMSISDN={errorMSISDN}
              setErrorMSISDN={setErrorMSISDN}
            />
            <ErrorMessage name={field.props.name} errors={values.errors} />
          </div>
        ))}
      </Grid>
    );
  };

  const eventRadioButton = (name, value) => {
    initialValue = {};
    initialValue[name] = value;
    section.forEach((sec) => {
      validaciones(sec.fields);
      findFieldsValidationsWithRadio(sec);
    });
  };

  const OnDependence = (item, value) => {
    let dependence = item.props.dependence;
    let isFirstItem = value === item.data[0].label;

    if (dependence) {
      let sec = section.find((obj) => {
        return obj.sectionName === dependence.split(".")[0];
      });

      let select = sec.fields.find(({ props }) => {
        return props.name === dependence.split(".")[1];
      });

      if (select.data[0].dependence === undefined) {
        let depend = item.data.find((x) => x.defaulValue.toUpperCase() === value.toUpperCase());
        let data = select.data.find((x) => x.defaulValue.toUpperCase() === depend.dependence.toUpperCase());
        item.setFieldValue(`${select.props.name}`, data.defaulValue);
      } else {
        item.setFieldValue(`${select.props.name}`, select.data[0].label);
        if (isFirstItem) {
          item.setFieldValue(`data_${select.props.name}`, select.data);
        } else {
          let data = select.data.filter(
            (x) => x.dependence.toUpperCase() === value.toUpperCase() || x.dependence === ""
          );
          item.setFieldValue(`data_${select.props.name}`, data);
        }
      }
    }
  };

  const OnDependenceInput = (item, value) => {
    let inputDependence = item.props.inputDependence;

    if (inputDependence) {
      let secIndex = section.findIndex((obj) => {
        return obj.sectionName === inputDependence.split(".")[0];
      });
      let sec = section.find((obj) => {
        return obj.sectionName === inputDependence.split(".")[0];
      });

      let selectedInputIndex = sec.fields.findIndex(({ props }) => {
        return props.name === inputDependence.split(".")[1];
      });

      if (value === "DNI" && section[secIndex].fields[selectedInputIndex].props.type === "text") {
        section[secIndex].fields[selectedInputIndex].props.prevType = "text";
        section[secIndex].fields[selectedInputIndex].props.type = "numeric";
        section[secIndex].fields[selectedInputIndex].props.detail = {
          max: 13,
          decimal: 0,
          split: true,
        };
      } else if (value !== "DNI" && section[secIndex].fields[selectedInputIndex].props.prevType === "text") {
        section[secIndex].fields[selectedInputIndex].props.type =
          section[secIndex].fields[selectedInputIndex].props.prevType;
      }
    }
  };

  const OnChanceDepence = (item, value) => {
    OnDependence(item, value);
    OnDependenceInput(item, value);
  };

  const handleResize = () => {
    setProps({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [userData]);

  useEffect(() => {
    if (screen !== form.stageName) {
      setScreen(form.stageName);
      if (isWorkingCapital) {
        let wcSegment = GetWCProperties(form.stageName, "load");
        window.analytics.page(wcSegment.event, wcSegment.properties);
      }
    }
  }, [form, screen]);

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = () => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
      if (!element) return;

      // Scroll to first known error into view
      element.focus();

      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };

  const FormClasess = () => {
    if (isWorkingCapital) {
      return `${formClass} ${classesKym.FormKym} v1 wcOverflowForm`;
    }
    return `${formClass} ${classesKym.FormKym}` + " v1";
  };

  const BoxFormClasess = () => {
    if (isWorkingCapital) {
      return `${classesKym.gridSectionForm} wcBoxForm`;
    }
    return `boxForm ${classesKym.gridSectionForm}`;
  };

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      validationSchema={getYup(validates)}
      onSubmit={(valores) => {
        let data = [];
        data = valores;
        if (isLegacy) {
          btnNextLegacy(data);
        } else {
          btnNext(data);
        }
      }}
    >
      {(props) => {
        const { values, setFieldValue, errors, submit } = props;

        return (
          <Grid container className={`${classesKym.mainGridContainer} ${classesKym[classesForm.classGridContainer]}`}>
            <Formx className={FormClasess()}>
              <ScrollToFieldError />
              <Grid item className={BoxFormClasess()}>
                <Grid container spacing={2} mt={1} className={classesKym.gridForm}>
                  {section.map((sec, j) => {
                    if (sec.hasOwnProperty("radio")) {
                      if (initialValue[sec.radio.name] === sec.radio.value) {
                        return sectionBuild(sec, j, {
                          values,
                          setFieldValue,
                          errors,
                        });
                      }
                    } else {
                      return sectionBuild(sec, j, {
                        values,
                        setFieldValue,
                        errors,
                        idpdv,
                        temporalData,
                        fileLoaded,
                        selectItems,
                      });
                    }
                  })}
                </Grid>
                <FooterContainer>
                  {footer.map((field) => (
                    <div key={field.props.id}>
                      <GenericInput {...addErr(field, errors)} />
                      <ErrorMessage name={field.props.name} errors={errors} />
                    </div>
                  ))}
                </FooterContainer>
              </Grid>
              <Grid item>
                <GenericCellElement type={specialbuttons} classesKym={classesKym} classesForm={classesForm}>
                  {btns.map((btn) => (
                    <GenericButton
                      xs={btn.xs}
                      order={specialbuttons}
                      key={btn.id}
                      props={btn}
                      btnBefore={btnBefore}
                      btnNext={btnNext}
                      btnResendOTP={btnResendOTP}
                      submit={submit}
                      messageOTP={messageOTP}
                      pinComplete={pinComplete}
                      btnNextLegacy={btnNextLegacy}
                      btnLegacyValidateOTP={btnLegacyValidateOTP}
                    />
                  ))}
                </GenericCellElement>
              </Grid>
            </Formx>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default Form;

Form.propTypes = {
  phone: PropTypes.string,
};
