export function existingValueInProperties(valor, objeto) {
  for (const key in objeto) {
    if (objeto.hasOwnProperty(key)) {
      const propiedad = objeto[key];
      if (propiedad === valor) {
        return true;
      }
    }
  }
  return false;
}

export function validateObject(objeto) {
  for (let propiedad in objeto) {
    if (objeto[propiedad] === undefined) {
      return true;
    }
  }
  return false;
}

export function validateNumber(obj) {
  const regex = /\d/;

  for (let prop in obj) {
    if (regex.test(obj[prop]) || !isNaN(Number(obj[prop]))) {
      return true;
    }
  }
  return false;
}
