import * as React from "react";
import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";

import themeKym from "../../utils/themes/ThemeKym";
import { existingValueInProperties, validateNumber, validateObject } from "../../utils/utils_objects";

export default function ToggleButtonMap(data) {
  let { props, values, setFieldValue, setErrorMSISDN, errorMSISDN } = data;
  const [selected, setSelected] = useState(null);
  const classesKym = themeKym();
  const [lastepin, setlastepin] = useState();
  const [lastemoney, setlastemoney] = useState();
  const keys = ["emoney", "epin"];
  let epin = values.epin_cell_number;
  let emoney = values.emoney_cell_number;
  let label = props.label;
  const [numbers, setnumbers] = useState({ epin: epin, emoney: emoney });

  const listnumbers = () => {
    if (epin !== undefined && epin !== "") {
      setnumbers((prevNumbers) => ({ ...prevNumbers, epin: epin }));
      setlastepin(epin);
    }
    if (emoney !== undefined && emoney !== "") {
      setnumbers((prevNumbers) => ({ ...prevNumbers, emoney: emoney }));
      setlastemoney(emoney);
    }

    let lastnumber = { epin: lastepin, emoney: lastemoney };
    if (!validateObject(lastnumber)) {
      if (
        existingValueInProperties(epin, lastnumber) === false ||
        existingValueInProperties(emoney, lastnumber) === false
      ) {
        setFieldValue(props.name, "");
      }
    }
  };

  useEffect(() => {
    listnumbers();
  }, [values.emoney_cell_number, values.epin_cell_number]);

  const handleClick = (value) => {
    setSelected(value);
    setFieldValue(props.name, value);
    setErrorMSISDN(false);
  };

  return (
    <div>
      <label id={props.id} className={classesKym[`${props.className.labelClassName}`]}>
        {label}
      </label>
      <Grid container justifyContent="start">
        {keys.map(
          (value) =>
            validateNumber(numbers) &&
            numbers[value] !== "No aplica" &&
            numbers[value] !== undefined &&
            numbers[value] !== "" &&
            (value === "epin" ? numbers[value] !== numbers["emoney"] : true) && (
              <Grid key={numbers[value]} mr={1}>
                <ToggleButton
                  key={numbers[value]}
                  value={numbers[value]}
                  selected={selected === numbers[value]}
                  className={
                    classesKym[props.className.toggleButton] +
                    (values.active_cell_number === numbers[value] ? " " + classesKym.toggleSelected : "")
                  }
                  onChange={() => handleClick(numbers[value])}
                >
                  {numbers[value]}
                </ToggleButton>
              </Grid>
            )
        )}
        <Grid style={{ color: "red", fontSize: "12px", textAlign: "justify", display: errorMSISDN ? "block" : "none" }}>
          Es necesario contar con una billetera Tigo Money, acércate a tu supervisor para poder avanzar ...
        </Grid>
      </Grid>
    </div>
  );
}
